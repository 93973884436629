export class Cookie {
    static setCookie(name: string, value: string, days?: number, hours?: number, minutes?: number): void {
        let expires = '';
        if (days !== undefined || hours !== undefined || minutes !== undefined) {
            const date = new Date();
            let timeToAdd = 0;

            if (days !== undefined) {
                timeToAdd += days * 24 * 60 * 60 * 1000; // Convert days to milliseconds
            }
            if (hours !== undefined) {
                timeToAdd += hours * 60 * 60 * 1000; // Convert hours to milliseconds
            }
            if (minutes !== undefined) {
                timeToAdd += minutes * 60 * 1000; // Convert minutes to milliseconds
            }

            date.setTime(date.getTime() + timeToAdd);
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    static getCookie(name: string): string | null {
        const nameEq = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEq) === 0) return c.substring(nameEq.length, c.length);
        }
        return null;
    }

    static deleteCookie(name: string): void {
        // Setting Max-Age to a negative value to delete the cookie
        document.cookie = name + '=; Max-Age=-99999999;';
    }
}
